

<template>
  <div class="main-wrapper home">
    <!--====================================
廣告橫幅
===================================== -->
    <section class="rev_slider_wrapper fullwidthbanner-container over" dir="ltr">
      <div id="rev_slider_1" class="rev_slider rev-slider-kidz-school" data-version="5.4.5" style="display: none">
        <ul>
          <li data-transition="fade">
            <a href="https://www.kc-mocktest.com.tw/#/" target="_blank">
              <img src="/assets/img/banner/2025卓越盃升私中網站廣告Banner1920x450.jpg" alt="卓越盃升私中模擬考" class="rev-slidebg" />
            </a>
          </li>
          <li data-transition="fade">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLScVWxf47TfX0DN-VZgGRBzmCqoOGLJU55_HNrphrI-SmF-J6g/viewform" target="_blank">
              <img src="/assets/img/banner/卓越盃Banner-高雄校區.jpg" alt="高雄校區" class="rev-slidebg" />
            </a>
          </li>
          <li data-transition="fade">
            <a href="https://www.k9books.com.tw/K9Web/Index?go=ModalALL&CDC=B41" target="_blank">
              <img src="/assets/img/banner/1030雙11-卓越盃首頁輪播-1920x450.jpg" alt="遇見雙十搶GO" class="rev-slidebg" />
            </a>
          </li>
          
          <li data-transition="fade">
            <a href="https://www.top945.com.tw/promo/20240906/index?cf=061177" target="_blank">
              <img src="/assets/img/banner/20240906.gif" alt="免費索取試讀本" class="rev-slidebg" />
            </a>
          </li>
          <li data-transition="fade">
            <a href="/#/Examinee/FormTeam">
              <img src="/assets/img/banner/1920x450.png" alt="卓越盃" class="rev-slidebg" />
            </a>
          </li>
          <li data-transition="fade">
            <a href="/#/Examinee/FormTeam">
              <img src="/assets/img/banner/卓越盃BANNER17.jpg" alt="卓越盃BANNER17.jpg" class="rev-slidebg" />
            </a>
          </li>
          <!-- <li data-transition="fade"
            v-for="item in Banner" :key="item.contentId">
            <a :href="item.url" target="_blank">
              <img :src="item.imagURL" :alt="item.title" class="rev-slidebg" />
            </a>
          </li> -->
        </ul>
      </div>
    </section>

    <!-- ====================================
最新消息
===================================== -->
    <section class="pt-9 pb-6 py-md-7">
      <div class="container">
        <div class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp"
          style="visibility: visible; animation-name: fadeInUp">
          <span class="shape shape-left bg-info"></span>
          <h2 class="text-danger">最新消息</h2>
          <span class="shape shape-right bg-info"></span>
        </div>
        <div class="row wow fadeInUp">

          <div class="mb-4">
            <div class="rounded">
              <ul class="list-unstyled mb-0">
                <li class="border-bottom p-3" v-for="item in List" :key="item.ne_DATE">

                  <div class="media">
                    <div class="media-body newname">
                      <h5 class="mb-1">
                        <span class="text-secondary" aria-hidden="true">．</span>
                        <a :href="'#/About/News/?id=' + item.contentId" :title="item.title"
                          class="btn-link font-base text-hover-purple">
                          {{ item.title }}</a>
                      </h5>
                    </div>
                    <div class="media-body newdate">
                      <time class="text-muted">{{ yearConvert(item.createDate) }}</time>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- ====================================
考程表
===================================== -->
    <section class="pt-9 py-md-8 bg-ligblue" id="teachers"
      style="background-image: url(/assets/img/background/avator-bg.png)">
      <div class="container">
        <div class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp"
          style="visibility: visible; animation-name: fadeInUp">
          <span class="shape shape-left bg-info"></span>
          <h2 class="text-danger">2024年 考程表</h2>
          <span class="shape shape-right bg-info"></span>
        </div>

        <div class="fadeInUp" dir="ltr">
          <div class="testable">
            <div class="position-relative x-auto">
              <img data-src="/assets/img/background/table.png" src="/assets/img/background/table.png"
                alt="carousel-img" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- ====================================
推薦閱讀
===================================== -->
    <section class="pt-9 pb-7" id="blog">
      <div class="container">
        <div class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp">
          <span class="shape shape-left bg-info"></span>
          <h2 class="text-danger">推薦閱讀</h2>
          <span class="shape shape-right bg-info"></span>
        </div>

        <div class="row wow fadeInUp">
          <div class="col-md-4" v-for="item in Talk.slice(0, 3)" :key="item.id">
            <div class="card">
              <div class="position-relative indfimg">
                <a :href="'#/Talk/Expert/?id=' + item.id" v-if="item.icon != ''">
                  <img class="card-img-top lazyestload" :data-src="item.icon" :src="item.icon" :alt="item.title" />
                </a>
                <a :href="'#/Talk/Expert/?id=' + item.id" v-if="item.icon == ''">
                  <img class="card-img-top lazyestload" data-src="/assets/img/empty.jpg" src="/assets/img/empty.jpg"
                    :alt="item.title" />
                </a>
                <div class="card-img-overlay p-0">
                  <span class="badge bg-danger badge-rounded m-4">
                    {{ item.category.substr(0, 2) }}<br>{{ item.category.substr(2, 3) }}</span>
                </div>
              </div>

              <div class="card-body border-top-5 px-3 border-danger">
                <h3 class="card-title">
                  <a class="text-danger text-capitalize d-block text-truncate" :href="'#/Talk/Expert/?id=' + item.id">{{
                    item.title }}</a>
                </h3>
                <ul class="list-unstyled d-flex flex-md-column flex-lg-row">
                  <li class="me-2">
                    <a class="text-muted" href="#">
                      <i class="fa fa-user me-2" aria-hidden="true"></i>{{ item.author }}
                    </a>
                  </li>
                </ul>
                <p class="mb-2">
                  {{ item.content.replace(`/<\ / ? [^>] +> /gi`, " ").substr(0, 80) }}
                    ...
                </p>
                <a class="btn btn-link text-hover-danger ps-0" :href="'#/Talk/Expert/?id=' + item.id">
                  <i class="fas fa-angle-double-right me-1" aria-hidden="true"></i>
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- ====================================
相關出版品
===================================== -->
    <section class="pb-7 py-md-x" id="gallery_home">
      <div class="container">
        <div class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp">
          <span class="shape shape-left bg-info"></span>
          <h2 class="text-danger">相關出版品</h2>
          <span class="shape shape-right bg-info"></span>
        </div>

        <div class="d-flex justify-content-center wow fadeInUp">
          <div id="filters" class="button-group">
            <!-- <button class="button is-checked" data-filter=".all">全部</button> -->
            <button class="button is-checked" data-filter=".charity">數學競賽</button>
            <button class="button" data-filter=".nature">閱讀競賽</button>
            <button class="button" data-filter=".childhood">數位課程</button>
          </div>
        </div>

        <div id="gallery-grid">
          <div class="row grid wow fadeInUp">

            <div class="col-md-4 col-lg-3 col-xs-12 element-item all" v-for="item in Publish.slice(0, 8)" :key="item.id">
              <div class="media media-hoverable justify-content-center">
                <div class="position-relative w-100">
                  <img class="media-img w-100" :data-src="item.icon" :src="item.icon" :alt="item.title" />
                  <a class="media-img-overlay" data-fancybox="gallery" data-bs-toggle="modal"
                    :data-bs-target="'#publicationAll_' + item.id" href="#">
                    <div class="btn btn-squre text-white">
                      <i class="fas fa-search-plus"></i>詳細介紹
                    </div>
                  </a>

                  <div class="modal fade mb-8" :id="'publicationAll_' + item.id" tabindex="-1"
                    :aria-labelledby="'publicationAll_' + item.id" aria-hidden="true" style="display: none;">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">{{ item.title }}</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <p v-html="item.content"></p>
                          <p class="text-danger">出版年級：<span v-html="item.level"></span></p>
                          <p>
                          <ul>
                            <li v-if="item.size != ''">尺寸：{{ item.size }}</li>
                            <li v-if="item.page != ''">頁數：{{ item.page }}</li>
                            <li v-if="item.color != ''">印刷：{{ item.color }}</li>
                          </ul>
                          </p>
                          <div v-html="item.note"
                            class="alert alert-warning d-flex align-items-center alert-dismissible fade show"
                            role="alert"></div>
                        </div>
                        <div class="modal-footer">
                          <div v-if="item.url != ''"><a @click="functionUrlClick(item.type, item.url, item.id)" 
                              class='btn btn-primary text-white text-uppercase'>立即搶購</a></div>
                          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-lg-3 col-xs-12 element-item charity" v-for="item in P1.slice(0, 8)" :key="item.id">
              <div class="media media-hoverable justify-content-center">
                <div class="position-relative w-100">
                  <img class="media-img w-100" :data-src="item.icon" :src="item.icon" :alt="item.title" />
                  <a class="media-img-overlay" data-fancybox="gallery" data-bs-toggle="modal"
                    :data-bs-target="'#publication1_' + item.id" href="#">
                    <div class="btn btn-squre text-white">
                      <i class="fas fa-search-plus"></i>詳細介紹
                    </div>
                  </a>

                  <div class="modal fade mb-8" :id="'publication1_' + item.id" tabindex="-1"
                    :aria-labelledby="'publication1_' + item.id" aria-hidden="true" style="display: none;">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">{{ item.title }}</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <p v-html="item.content"></p>
                          <p class="text-danger">出版年級：<span v-html="item.level"></span></p>
                          <p>
                          <ul>
                            <li v-if="item.size != ''">尺寸：{{ item.size }}</li>
                            <li v-if="item.page != ''">頁數：{{ item.page }}</li>
                            <li v-if="item.color != ''">印刷：{{ item.color }}</li>
                          </ul>
                          </p>
                          <div v-html="item.note"
                            class="alert alert-warning d-flex align-items-center alert-dismissible fade show"
                            role="alert"></div>

                        </div>
                        <div class="modal-footer">
                          <div v-if="item.url != ''"><a @click="functionUrlClick(1, item.url, item.id)" 
                              class='btn btn-primary text-white text-uppercase'>立即搶購</a></div>
                          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-lg-3 col-xs-12 element-item nature" v-for="item in P2.slice(0, 8)" :key="item.id">
              <div class="media media-hoverable justify-content-center">
                <div class="position-relative w-100">
                  <img class="media-img w-100" :data-src="item.icon" :src="item.icon" :alt="item.title" />
                  <a class="media-img-overlay" data-fancybox="gallery" data-bs-toggle="modal"
                    :data-bs-target="'#publication2_' + item.id" href="#">
                    <div class="btn btn-squre text-white">
                      <i class="fas fa-search-plus"></i>詳細介紹
                    </div>
                  </a>

                  <div class="modal fade mb-8" :id="'publication2_' + item.id" tabindex="-1"
                    :aria-labelledby="'publication2_' + item.id" aria-hidden="true" style="display: none;">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">{{ item.title }}</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <p v-html="item.content"></p>
                          <p class="text-danger">出版年級：<span v-html="item.level"></span></p>
                          <p>
                          <ul>
                            <li v-if="item.size != ''">尺寸：{{ item.size }}</li>
                            <li v-if="item.page != ''">頁數：{{ item.page }}</li>
                            <li v-if="item.color != ''">印刷：{{ item.color }}</li>
                          </ul>
                          </p>
                          <div v-html="item.note"
                            class="alert alert-warning d-flex align-items-center alert-dismissible fade show"
                            role="alert"></div>

                        </div>
                        <div class="modal-footer">
                          <div v-if="item.url != ''"><a @click="functionUrlClick(2, item.url, item.id)" 
                              class='btn btn-primary text-white text-uppercase'>立即搶購</a></div>
                          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-lg-3 col-xs-12 element-item childhood" v-for="item in P3.slice(0, 8)" :key="item.id">
              <div class="media media-hoverable justify-content-center">
                <div class="position-relative w-100">
                  <img class="media-img w-100" :data-src="item.icon" :src="item.icon" :alt="item.title" />
                  <a class="media-img-overlay" data-fancybox="gallery" data-bs-toggle="modal"
                    :data-bs-target="'#publication3_' + item.id" href="#">
                    <div class="btn btn-squre text-white">
                      <i class="fas fa-search-plus"></i>詳細介紹
                    </div>
                  </a>

                  <div class="modal fade mb-8" :id="'publication3_' + item.id" tabindex="-1"
                    :aria-labelledby="'publication3_' + item.id" aria-hidden="true" style="display: none;">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">{{ item.title }}</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <p v-html="item.content"></p>
                          <p class="text-danger">出版年級：<span v-html="item.level"></span></p>

                          <div v-html="item.note"
                            class="alert alert-warning d-flex align-items-center alert-dismissible fade show"
                            role="alert"></div>

                        </div>
                        <div class="modal-footer">
                          <div v-if="item.url != ''"><a @click="functionUrlClick(3, item.url, item.id)" 
                              class='btn btn-primary text-white text-uppercase'>立即搶購</a></div>
                          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="btn-aria text-center mt-4 wow fadeInUp">
          <a href="/#/About/Publish" class="btn btn-danger text-uppercase box-shadow">View More</a>
        </div>
      </div>
    </section>
  </div>
</template>
<style src="../assets/css/kidz.css" scoped>
</style>
<script>
import expertJson from "../assets/data/talk/expert.json";
//import parentJson from "../assets/data/talk/parent.json";
import jsonP1 from "../assets/data/publish/P1.json";
import jsonP2 from "../assets/data/publish/P2.json";
import jsonP3 from "../assets/data/publish/P3.json";
export default {
  name:'home-component',
  // inject: ['reload'],
  data() {
    return {
      url: window.location.href,
      // phoneBarOpen: false,
      pagination: {
        perPage: 5,
        page: 1,
      },
      List: [],
      Banner: [],
      Talk: [],
      expert: [],
      parent: [],
      Publish: [],
      P1: [],
      P2: [],
      P3: [],
      isFirstLoad: true,
      shouldForceUpdate: false,

      apiContent: (data) => this.userRequest.post("KC/Content", data),
      apiLog: (data) => this.userRequest.post("KC/Log", data),
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.List = [];
      this.albumList = [];
      this.loading = true;
      let json = {
        TYPE: "HOME_LIST",
        content_SELECT: {
          pageIndex: this.pagination.page,
          pageSize: this.pagination.perPage,
          menuId: 301,
        },
      };
      this.FunctionToken(this.GetContent, json);
      let jsonBanner = {
        TYPE: "HOME_BANNER_LIST",
      };
      this.FunctionToken(this.GetBannerContent, jsonBanner);

      this.Talk = [];
      let expert = expertJson;
      this.GetTalkContent(expert);

      let p1 = jsonP1;
      let p2 = jsonP2;
      let p3 = jsonP3;
      this.P1 = p1.sort((t1, t2) => t1.position < t2.position ? -1 : 1);
      this.P2 = p2.sort((t1, t2) => t1.position < t2.position ? -1 : 1);
      this.P3 = p3.sort((t1, t2) => t1.position < t2.position ? -1 : 1);
      let publish = [];
      Object.assign(publish, p1, p2, p3);
      this.Publish = publish.sort((t1, t2) => t1.position > t2.position ? -1 : 1);
    },

    GetTalkContent(expert) {
      this.Talk = expert;//Object.assign(expert);
      this.Talk = this.Talk.sort((t1, t2) => t1.publicDate > t2.publicDate ? -1 : 1);
    },
    //------------api
    GetContent(data) {
      this.apiContent(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.List = jsonData.outData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetBannerContent(data) {
      this.apiContent(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.Banner = jsonData.outData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    functionUrlClick(type, url, id) {
      let json = {
        TYPE: "PUBLISH_HITS",
        Publish_SELECT: {
          Type: type,
          Id: id,
          Url: url,
        },
      };
      this.FunctionToken(this.GetUrlClick, json);
    },
    GetUrlClick(data) {
      this.apiLog(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            window.open(json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    reloadPage() {
      if (this.isFirstLoad) {
        location.reload();
        this.isFirstLoad = false;
      }
    }
  },
};

</script>