<template>
  <div>
    <Header />

    <!--資優數學營內容區-->
    <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb-4">
            <ul role="tablist" class="nav nav-pills mb-3 pt-6 pb-4 lh-lg">
              <li class="nav-item">
                <a
                  href="/#/Camp"
                  role="tab"
                  aria-controls="introduce"
                  class="nav-link active"
                  >營隊介紹</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="/#/Camp/Step"
                  role="tab"
                  aria-controls="step"
                  class="nav-link"
                  >營隊報名</a
                >
              </li>
              <li class="nav-item" v-if="isCampSearchOpen == 'true'">
                <a
                  href="/#/Camp/Search"
                  role="tab"
                  aria-controls="search"
                  class="nav-link"
                  >營隊查詢</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="/#/Camp/Faq"
                  role="tab"
                  aria-controls="faq"
                  class="nav-link"
                  >常見問題</a
                >
              </li>
            </ul>

            <div id="myTabContent" class="tab-content">
              <div class="row">
                <div class="col-12">
                  <div
                    class="section-title justify-content-center mb-4 mb-md-8"
                  >
                    <span class="shape shape-left bg-info"></span>
                    <h2 class="text-danger">營隊介紹</h2>
                    <span class="shape shape-right bg-info"></span>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <div class="">
                    <div class="section-title mb-2">
                      <div class="text-danger camptit">壹、營隊宗旨</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12 col-md-8">
                  <p class="font-size-20">
                    「卓越盃資優數學營」邀集數學競賽表現優異的考生一同參與課程，主辦單位特地為這些數學高手量身打造數學菁英課程，透過營隊彼此相互切磋交流學習，深化數學興趣及能力。六天五夜的營隊活動，除了數學素養的啓發，也讓孩子學習如何生活自理，學習獨立不依賴父母。卓越盃資優數學營，將以最精彩的課程，迎接每一位數學小菁英！
                  </p>
                </div>
                <div class="col-12 col-md-4 order-0 order-md-1">
                  <img
                    class="img-fluid"
                    src="/assets/img/camp/camp1.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <div class="media mb-6 align-items-center">
                    <div class="media-icon-large bg-primary me-xl-4">
                      <i aria-hidden="true" class="fas fa-graduation-cap"></i>
                    </div>
                    <div class="media-body">
                      <h3 class="text-primary">打造數學菁英課程 精進數學能力</h3>
                    </div>
                  </div>
                  <div class="mb-4 font-size-20">
                    <ol class="cjk-list">
                      <li>
                        大師專題演講──邀請數學教育專家，獨家解析本年度數學競賽試題、分享數學知識領域，深厚學生的專業素養。
                      </li>
                      <li>
                        分組專題探討──藉由小組合作討論、動手操作、分組競賽等，引領學生應用數學知識思考問題解決方法。
                      </li>
                      <li>
                        分組數學操作──透過小隊分組、團隊競賽、住宿生活，可以提升孩子人際溝通能力，經由這些體驗不但能讓孩子脫離舒適圈，更能學會如何與同儕相處建立同理心，並培養獨立自主的能力。
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <div class="media mb-6 align-items-center">
                    <div class="media-icon-large bg-primary me-xl-4">
                      <i aria-hidden="true" class="fas fa-graduation-cap"></i>
                    </div>
                    <div class="media-body">
                      <h3 class="text-primary">多元化營隊活動 儲備未來競爭力</h3>
                    </div>
                  </div>
                  <div class="mb-4 font-size-20">
                    <p class="font-size-20">
                      規劃多元的團體活動課程：如大地遊戲、歡樂營火等，讓孩子輕鬆地在充滿綠意的康橋校園呼吸最純淨的山中芬多精，晚上還有精彩的團康活動，讓孩子融入快樂的團體生活，體驗各種營隊生活樂趣。
                    </p>
                  </div>
                </div>
                <div class="col-12 col-md-3 mb-4">
                  <img
                    class="img-fluid"
                    src="/assets/img/camp/camp2.jpg"
                    alt=""
                  />
                </div>
                <div class="col-12 col-md-3 mb-4">
                  <img
                    class="img-fluid"
                    src="/assets/img/camp/camp3.jpg"
                    alt=""
                  />
                </div>
                <div class="col-12 col-md-3 mb-4">
                  <img
                    class="img-fluid"
                    src="/assets/img/camp/camp4.jpg"
                    alt=""
                  />
                </div>
                <div class="col-12 col-md-3 mb-4">
                  <img
                    class="img-fluid"
                    src="/assets/img/camp/camp5.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <div class="">
                    <div class="section-title mb-2">
                      <div class="text-danger camptit">貳、營隊日期及參加對象</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <div class="mb-4 font-size-20">
                    <ol class="cjk-list">
                      <li>
                        2025年第十四屆卓越盃資優數學營日期：<span class="text-danger">2025年2月2日至2月7日，共六天五夜。</span>
                      </li>
                      <li>
                        報名資格為國小三～六年級小朋友參加2024年卓越盃全國競賽數學成績銅質獎以上。
                      </li>
                    </ol>
                    <div class="row">
                          <div class="col-12 col-md-3 mb-4">
                            <img
                              class="img-fluid"
                              src="/assets/img/camp/camp6.jpg"
                              alt=""
                            />
                          </div>
                          <div class="col-12 col-md-3 mb-4">
                            <img
                              class="img-fluid"
                              src="/assets/img/camp/camp7.jpg"
                              alt=""
                            />
                          </div>
                          <div class="col-12 col-md-3 mb-4">
                            <img
                              class="img-fluid"
                              src="/assets/img/camp/camp8.jpg"
                              alt=""
                            />
                          </div>
                          <div class="col-12 col-md-3 mb-4">
                            <img
                              class="img-fluid"
                              src="/assets/img/camp/camp9.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <div class="">
                    <div class="section-title mb-2">
                      <div class="text-danger camptit">參、營隊課程</div>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <div class="mb-4 font-size-20">
                    <!-- <p class="font-size-20">以下為2025年營隊最新課程，主辦單位得依活動狀況，適度調整課程內容： </p> -->
                    <ol class="cjk-list">
                      <li>
                        <b>多元宇宙</b><br>
                        索瑪立方體是第五十五屆國小科展的得獎作品，用各式各樣的多連立方體去拼湊成正立方體，能夠有效的訓練學員們的空間思維和邏輯推論的能力，過程中除了基本的體積概念之外，不同體積的多面體都有特別的技巧，你們有信心可以完成索瑪立方體的挑戰嗎？
                      </li>
                      <li>
                        <b>海島傳說</b><br>
                        將這堂課的卡牌遊戲設定為古代的博弈遊戲背景，透過設計過的卡牌運用類似撿紅點的遊戲規則，讓學員能夠在遊玩跟上課過程中熟悉指數的概念和運算，同時加入負數的次方讓學員更了解分數跟指數的關係，現在就輪到你們來互相競爭了，究竟誰才能成為裡面的第一名呢？
                      </li>
                      <li>
                        <b>一鼓作氣</b><br>
                        課程以《蠟筆小新》為背景，透過骨牌小遊戲提升學員的邏輯推理與數學概念，並讓他們建立出有條理的思考能力。學員們將會在課程中學習如何分析對手牌型，鍛鍊策略思維與分析技巧。快跟著小新一起冒險，打敗怪盜「撲克雙魔」，開啟拯救巧克比大作戰吧！
                      </li>
                      <li>
                        <b>腦筋急轉彎</b><br>
                        將以《腦筋急轉彎》作為課程背景，帶領學員深入探索火柴遊戲的概念與技巧，提升動手操作、認知理解、計算能力和邏輯思維。準備好跟隨情緒們展開刺激的腦力冒險，解開火柴謎題，進入大腦的神秘領域，一起拯救萊利的幻想朋友Bing Bong吧！
                      </li>
                      <li>
                        <b>獨一無二</b><br>
                        數獨是一個廣受歡迎的邏輯遊戲，規則簡單明瞭，只需在小九宮格及行列中填入數字1到9，並確保不重複即可。然而，當這些簡單的規則和四則運算還有幾何圖形的變化結合，再加上《鬼滅之刃》的背景，九宮格就變成了一個戰鬥位置圖，挑戰接踵而來。你的任務是破解每一關的布局，打敗敵人，並成功解救產屋敷，準備好迎接這個艱巨的任務了嗎？
                        
                      </li>
                      <li>
                        <b>Triangle配</b><br>
                        課程內容以小小兵的故事為主題，運用幾何圖形中的三角形和日常生活的物品做相互連結，並透過三角形全等性質配對遊戲，讓學員在遊戲中能夠學習到各種全等類型，也能更理解三角形的特性。讓我們跟著小小兵一起打敗偷走三角形物品的維克特吧！
                      </li>
                      <li>
                        <b>七大罪</b><br>
                        這堂課以「七宗罪」為主題的卡牌遊戲，讓學員透過卡牌上的方程式進行對戰。雖然過程中僅需解出簡單的未知數，但在這解題的同時，必須預測對方的動作並反制。加入了人性的不可測因素，讓每場對決都充滿變數和未知，準備好迎接這場心智的挑戰嗎？那就來一決高下！
                      </li>
                      <li>
                        <b>諸神黃昏</b><br>
                        在壯麗的希臘神話戰場上，這款遊戲將帶學員進入一場精彩的冒險，每張卡牌在不同地點擁有獨特的效果，但只能依據固定的順序行動，無法自由選擇。這不僅考驗手中的卡牌，更需深思與隊友的策略與合作，在這些條件下，你是否有信心帶領你的小隊，在這片神話戰場上無往不利？如果你準備好了，那就來戰場上切磋吧！
                      </li>
                      <li>
                        <b>因數小子</b><br>
                        歐基里德對局遊戲是個古老的遊戲，旨意在於讓學生理解尋找最大公因數的方法，課程綜合闖關挑戰魔王的故事，透過基礎的因倍數及質數觀念，延伸到輾轉相除法的使用，不只訓練學員的觀察力，也能鞏固觀念及引起學員挑戰的興趣，讓我們一起幫助索尼克挑戰並逃出密室。
                      </li>
                      <li>
                        <b>隨機奇想</b><br>
                        彩虹王國與黑白王國的戰爭一觸即發，運用擲硬幣和擲骰子等機率經典問題為切入點，帶領學員深入探索排列組合的奧秘，並進一步了解機率的概念。透過有趣的應用活動，學員將會學習到四色問題及幾A幾B遊戲，有效提升分析能力、邏輯思維及判斷力，快來跟我們一起拯救彩虹王國吧！
                        <div class="row mt-2">
                          <div class="col-12 col-md-3 mb-4">
                            <img
                              class="img-fluid"
                              src="/assets/img/camp/camp10.jpg"
                              alt=""
                            />
                          </div>
                          <div class="col-12 col-md-3 mb-4">
                            <img
                              class="img-fluid"
                              src="/assets/img/camp/camp11.jpg"
                              alt=""
                            />
                          </div>
                          <div class="col-12 col-md-3 mb-4">
                            <img
                              class="img-fluid"
                              src="/assets/img/camp/camp12.jpg"
                              alt=""
                            />
                          </div>
                          <div class="col-12 col-md-3 mb-4">
                            <img
                              class="img-fluid"
                              src="/assets/img/camp/camp13.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <div class="">
                    <div class="section-title mb-2">
                      <div class="text-danger camptit">肆、報名資訊</div>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <div class="mb-4 font-size-20">
                    <p class="font-size-20">以下為2025年營隊最新課程，主辦單位得依活動狀況，適度調整課程內容： </p>
                    <ol class="cjk-list">
                      <li>
                        營隊時間：<span class="text-danger">2025年2月2日至2月7日，共六天五夜。</span>
                      </li>
                      <li>
                        報名資格：國小三～六年級學生參加2024年卓越盃全國競賽數學成績銅質獎以上。成績符合資格方可透過官網營隊報名路徑，完成報名及繳費作業。
                      </li>
                      <li>
                        招生名額：依中、高年級分別編組，合計<span class="text-danger">共招收108人</span>，因名額有限，依完成報名及繳費作業先後次序，逾期或額滿即不受理（於網站上公告）。
                      </li>
                      <li>
                        營隊地點：康橋國際學校秀岡校區（新北市新店區華城路800號）。
                      </li>
                      <li>
                        營隊費用：<b class="text-danger">每位學員參加費用為新台幣19,800元整</b>（含報名費、食宿、交通、活動保險、課程材料等，高鐵費用另計）。
                      </li>
                      <li>
                        接送方式：為方便中南部地區的學生，特別安排於台中高鐵站、左營高鐵站協助往返接送，若有需要請於報名時勾選購票需，<span class="text-danger">本次營隊適逢春節返鄉輸運高峰，高鐵車票購買不易，主辦單位保留調整交通方式之權利。</span>（統一代購高鐵票，費用連同報名費繳交。主辦單位若已完成購票作業，即不再受理代購高鐵票，敬請見諒）。
                      </li>
                      <li>
                        報名方式：成績公告後符合資格之考生，請於官網營隊報名路徑，填妥報名相關資料，並完成線上刷卡付款，始算報名成功，逾期或額滿即不受理（於網站上公告）。
                      </li>
                      <li>
                        食宿環境：宿舍門禁設置磁卡鎖安全有保障，每間為4~6人之套房，具獨立空調、乾溼分離衛浴設備，提供洗衣服務。另菜色有營養師調配，並由學校中央廚房現場烹調供應，新鮮又美味。
                      </li>
                      <li>
                        生活照護：由專業隊輔人員負責照護小朋友營隊生活，並於每日晚餐前進行打電話報平安讓小朋友與家長分享營隊大小事。
                      </li>
                      <li>
                        營隊相關問題洽詢電話 (02)8665-1746（服務時間：週一 ~ 週五，09:00~12:00；13:30~17:30）
                      </li>
                    </ol>
                  </div>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-12">
                  <div class="mb-4 font-size-20">
                    <p class="font-size-20">※特別聲明※</p>
                    <ol>
                      <li>如成績未符合資格之考生，恕不受理報名。</li>
                      <li>本營隊為住宿型營隊，請評估孩子意願及狀況，再進行報名付費。如學員因故不克參加，依退費辦法辦理。</li>
                      <li class="text-danger">
                        本次營隊始業式2025/2/2(日)，適逢春節返鄉輸運高峰，高鐵車票購買不易，主辦單位保留調整交通方式之權利，敬請理解與配合。
                      </li>
                    </ol>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12 col-md-3 mb-4">
                      <img
                        class="img-fluid"
                        src="/assets/img/camp/camp14.jpg"
                        alt=""
                      />
                    </div>
                    <div class="col-12 col-md-3 mb-4">
                      <img
                        class="img-fluid"
                        src="/assets/img/camp/camp15.jpg"
                        alt=""
                      />
                    </div>
                    <div class="col-12 col-md-3 mb-4">
                      <img
                        class="img-fluid"
                        src="/assets/img/camp/camp16.jpg"
                        alt=""
                      />
                    </div>
                    <div class="col-12 col-md-3 mb-4">
                      <img
                        class="img-fluid"
                        src="/assets/img/camp/camp17.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                
              </div>

              <div class="row mb-4">
                <div class="col-12">
                  <div class="">
                    <div class="section-title mb-2">
                      <div class="text-danger camptit">伍、其他注意事項</div>
                    </div>
                  </div>
                  
                  
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <div class="mb-4 font-size-20">
                    <ol class="cjk-list">
                      <li>
                        為適應不可抗拒之因素(如天候變化等)，在不影響學生活動品質與權益範圍內，為維護課程學習效益，主辦單位將保留行程更動調整權利。若因天候及其他不可抗力因素有改期、取消或變動場地、變動班級梯次之必要，主辦單位將於第一時間公告通知。
                      </li>
                      <li>
                        所有學員在活動期間有故意不遵守紀律或妨礙他人正常進行活動者，主辦單位有權取消學員參加資格並通知家長提早帶回，且剩餘課程費用恕不退還。
                      </li>
                      <li>
                        活動期間，為考量所有學員權益與安全、團體活動之一致性及課程順利進行，除非有緊急狀況或主辦單位主動聯絡外，謝絕家長在未經告知的狀況下臨時參觀甚至加入活動。
                      </li>
                      <li>
                        建議參加活動之學員無須攜帶貴重物品，如電動玩具、手機等3C產品及過多零用錢等，若發生遺失、毀損、借用糾紛等情事，主辦單位概不負責。
                      </li>
                      <li>
                        本營隊包含各項戶外活動，若有不適合參與活動之疾痛或身體不適之疾病、症狀，請勿勉強報名。若仍報名，其責任由報名本人及監護人自行分擔，並請於報名表中確實填寫告知，以便隨時注意學員情況，若因故有需要終止或暫停活動，剩餘課程費用恕不退還。
                      </li>
                      <li>
                        活動期間所拍攝之活動影音、照片，均為主辦單位無償使用；學員於活動期間所完成的短文、作品圖片等創作，若無提出特殊聲明，亦視同同意主辦單位於網路、平面文宣等媒體公開使用。
                      </li>
                    </ol>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12 col-md-3 mb-4">
                      <img
                        class="img-fluid"
                        src="/assets/img/camp/camp18.jpg"
                        alt=""
                      />
                    </div>
                    <div class="col-12 col-md-3 mb-4">
                      <img
                        class="img-fluid"
                        src="/assets/img/camp/camp19.jpg"
                        alt=""
                      />
                    </div>
                    <div class="col-12 col-md-3 mb-4">
                      <img
                        class="img-fluid"
                        src="/assets/img/camp/camp20.jpg"
                        alt=""
                      />
                    </div>
                    <div class="col-12 col-md-3 mb-4">
                      <img
                        class="img-fluid"
                        src="/assets/img/camp/camp21.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <!--資優數學營內容區結束-->
  </div>
</template>

<script>
import Header from "./Header.vue";

export default {
  data() {
    return {
      newsDetail: {},
      isCampOpen: false,
      isCampApplyOpen: false,
      isCampSearchOpen: false,
      apiContent: (data) => this.userRequest.post("KC/Content", data),
    };
  },
  components: {
    Header,
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getCampIsOpen();
      await this.getCampApplyIsOpen();
      await this.getCampSearchIsOpen();
      if (this.isCampOpen == "false") {
        alert(new Date().getFullYear() + "年度尚未開放資優營");
        location.href = "/#/";
      }

      this.getDetail(4566);
    },
    getDetail(value) {
      this.newsDetail = {};
      let json = {
        TYPE: "HOME_DETAIL",
        content_SELECT: {
          contentId: value,
        },
      };
      this.FunctionToken(this.GetContentDetail, json);
    },
    GetContentDetail(data) {
      this.apiContent(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.newsDetail = {
              title: jsonData.outWeb.title,
              createDate: jsonData.outWeb.createDate,
              content: jsonData.outWeb.contents,
              modifyDate: jsonData.outWeb.modifyDate,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getCampIsOpen() {
      try {
        let dataJSON = {
          TYPE: "FRONT_CAMP_ISOPEN",
        };

        let resDATA = await this.userRequest.post("KC/CampYearData", dataJSON);
        let jsonRdata = JSON.parse(resDATA.data);
        if (jsonRdata.Status) {
          this.isCampOpen = jsonRdata.Data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getCampApplyIsOpen() {
      try {
        let dataJSON = {
          TYPE: "FRONT_APPLY_ISOPEN",
        };

        let resDATA = await this.userRequest.post("KC/CampYearData", dataJSON);
        let jsonRdata = JSON.parse(resDATA.data);
        if (jsonRdata.Status) {
          this.isCampApplyOpen = jsonRdata.Data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getCampSearchIsOpen() {
      try {
        let dataJSON = {
          TYPE: "FRONT_SEARCH_ISOPEN",
        };

        let resDATA = await this.userRequest.post("KC/CampYearData", dataJSON);
        let jsonRdata = JSON.parse(resDATA.data);
        if (jsonRdata.Status) {
          this.isCampSearchOpen = jsonRdata.Data;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
