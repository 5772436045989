var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.openBooking == 'true')?_c('div',{staticClass:"signup"},[_vm._m(0)]):_vm._e(),(_vm.openExamSnSearching == 'true')?_c('div',{staticClass:"signup"},[_vm._m(1)]):_vm._e(),(_vm.isGradeQueryOpen == 'false' && _vm.isCampApplyOpen == 'true')?_c('div',{staticClass:"signup"},[_vm._m(2)]):_vm._e(),(_vm.isCampApplyOpen == 'false' && _vm.isGradeQueryOpen == 'true')?_c('div',{staticClass:"signup"},[_vm._m(3)]):_vm._e(),(_vm.isCampApplyOpen == 'true' && _vm.isGradeQueryOpen == 'true')?_c('div',{staticClass:"signup"},[_vm._m(4),_vm._m(5)]):_vm._e(),(_vm.isGradePublicOpen == 'false')?_c('div',{staticClass:"signup"},[_vm._m(6)]):_vm._e(),_c('footer',{staticClass:"footer-bg-white bg-light"},[_vm._m(7),_c('div',{staticClass:"py-8"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(8),_vm._m(9),_c('div',{staticClass:"col-sm-6 col-lg-2 col-xs-12"},[_c('ul',{staticClass:"list-unstyled"},[_c('li',{staticClass:"mb-4 fotbl"},[_c('a',{attrs:{"href":"/#/FAQ/EventInfo"},on:{"click":function($event){return _vm.scrollToTop()}}},[_vm._m(10),_vm._v(" 常見問題 ")])]),_vm._m(11),_vm._m(12),_vm._m(13)])]),_vm._m(14),_vm._m(15)])])]),_vm._m(16)]),_vm._m(17),_vm._m(18)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/#/Examinee/FormTeam"}},[_c('img',{attrs:{"src":"/assets/img/SignUp.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/#/Examinee/DataManage"}},[_c('img',{attrs:{"src":"/assets/img/SignUp3.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/#/Camp/Booking"}},[_c('img',{attrs:{"src":"/assets/img/SignUp2.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/#/HistoryGrade/Search"}},[_c('img',{attrs:{"src":"/assets/img/SignUp5.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/#/HistoryGrade/Search"}},[_c('img',{attrs:{"src":"/assets/img/SignUp4_1.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/#/Camp/Booking"}},[_c('img',{attrs:{"src":"/assets/img/SignUp4_2.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/#/HistoryGrade/Search"}},[_c('img',{attrs:{"src":"/assets/img/SignUp5.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"color-bar"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col color-bar bg-warning"}),_c('div',{staticClass:"col color-bar bg-danger"}),_c('div',{staticClass:"col color-bar bg-success"}),_c('div',{staticClass:"col color-bar bg-info"}),_c('div',{staticClass:"col color-bar bg-purple"}),_c('div',{staticClass:"col color-bar bg-pink"}),_c('div',{staticClass:"col color-bar bg-warning d-none d-md-block"}),_c('div',{staticClass:"col color-bar bg-danger d-none d-md-block"}),_c('div',{staticClass:"col color-bar bg-success d-none d-md-block"}),_c('div',{staticClass:"col color-bar bg-info d-none d-md-block"}),_c('div',{staticClass:"col color-bar bg-purple d-none d-md-block"}),_c('div',{staticClass:"col color-bar bg-pink d-none d-md-block"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-lg-3 col-xs-12"},[_c('a',{staticClass:"mb-6 d-block",attrs:{"href":"https://www.knsh.com.tw/","target":"_blank"}},[_c('img',{staticClass:"img-fluid d-inline-block w-90",attrs:{"data-src":"/assets/img/logo-kc.png","src":"/assets/img/logo-kc.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-lg-3 col-xs-12"},[_c('h4',{staticClass:"section-title-sm font-weight-bold text-danger mb-2"},[_vm._v("服務時間")]),_c('p',{staticClass:"mb-6"},[_vm._v(" 週一 ~ 週五 上午9：00 ~ 下午5：00"),_c('br'),_vm._v(" (午間12：00 ~ 13：45及例假日除外) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"bg-pink icon-header me-xl-2"},[_c('i',{staticClass:"fa fa-question",attrs:{"aria-hidden":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mb-4 fotbl"},[_c('a',{attrs:{"href":"https://www.facebook.com/profile.php?id=100057468308948","target":"_blank"}},[_c('span',{staticClass:"bg-fb icon-header me-xl-2"},[_c('i',{staticClass:"fab fa-facebook-f",attrs:{"aria-hidden":"true"}})]),_vm._v(" 卓越盃粉絲團 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mb-4 fotbl"},[_c('a',{attrs:{"href":"/#/About/MemberPrivacy"}},[_c('span',{staticClass:"bg-info icon-header me-xl-2"},[_c('i',{staticClass:"fa fa-users",attrs:{"aria-hidden":"true"}})]),_vm._v(" 會員使用條款 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mb-4 fotbl"},[_c('a',{attrs:{"href":"/#/About/Privacy"}},[_c('span',{staticClass:"bg-success icon-header me-xl-2"},[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}})]),_vm._v(" 隱私權政策 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-lg-2 col-xs-12 fotbl"},[_c('ul',{staticClass:"list-unstyled"},[_c('li',{staticClass:"mb-4"},[_c('a',{attrs:{"href":"https://line.me/ti/p/%40xwe6582i","target":"_blank"}},[_c('span',{staticClass:"bg-success icon-header me-xl-2"},[_c('i',{staticClass:"fab fa-line",attrs:{"aria-hidden":"true"}})]),_vm._v(" 加入好友 ")])]),_c('li',{staticClass:"mb-4"},[_c('div',{staticClass:"rounded-lg border-primary bg-white p-1"},[_c('img',{staticClass:"img-fluid",attrs:{"data-src":"/assets/img/qrcode.png","src":"/assets/img/qrcode.png","alt":"加入好友 QR CODE"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-lg-2 col-xs-12 fotbl"},[_c('ul',{staticClass:"list-unstyled"},[_c('li',{staticClass:"mb-4"},[_vm._v("贊助單位")]),_c('li',{staticClass:"mb-4"},[_c('a',{attrs:{"href":"https://www.yourclass.com.tw/","target":"_blank"}},[_c('img',{staticClass:"rounded-lg border-primary d-block",attrs:{"data-src":"/assets/img/logo_yourclass.svg","src":"/assets/img/logo_yourclass.svg","alt":"康軒學習網","width":"140"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row py-4 align-items-center"},[_c('div',{staticClass:"col-sm-12 col-xs-12 order-1 order-md-0"},[_c('p',{staticClass:"text-center text-sm-left font-size-13 mb-0"},[_vm._v(" 康軒文教事業 著作權所有 © 2024 建議使用 Microsoft Edge 或 Chrome 1280 x 720 以上解析度瀏覽本網站 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{staticClass:"back-to-top",staticStyle:{"opacity":"1"},attrs:{"href":"/#/FAQ/Important","id":"back-to-top2"}},[_c('img',{staticClass:"rounded-lg border-primary d-block",attrs:{"data-src":"/assets/img/fraud.png","src":"/assets/img/fraud.png","alt":"謹防詐騙"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('a',{staticClass:"back-to-top",staticStyle:{"opacity":"1","visibility":"visible"},attrs:{"href":"#pageTop","id":"back-to-top"}},[_c('i',{staticClass:"fas fa-arrow-up",attrs:{"aria-hidden":"true"}})])])
}]

export { render, staticRenderFns }